jQuery(document).ready(function ($) {
  /* ==================== Fonts ==================== */
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Poppins:300,400,600,700,900", ""],
    },
  });

  /* ==================== Menus ==================== */

  $( "<span class='menu-drop'><i class='fa fa-plus'></i></span>" ).appendTo( "#mobileNav .menu-item-has-children" );
  $( "#mobileNav .menu-item-has-children .menu-drop" ).on('click touch', function () {
    $(this).siblings( ".sub-menu-wrap" ).stop().slideToggle(500);
    $(this).toggleClass('sub-menu-active');
  });

  const mobileMenuToggle = document.querySelectorAll(".navToggle"),
    mobileMenu = document.getElementById("mobileNav"),
    header = document.querySelector("#header"),
    subMenuItems = document.querySelectorAll(".menu-item-has-children"),
    mobileSubMenuItems = document.querySelectorAll(
      "#mobileNav .menu-item-has-children"
    ),
    adminBar = document.getElementById("wpadminbar");

  mobileSubMenuItems.forEach((element) => {
    element.addEventListener("click", toggleSubMenu);
    element.addEventListener("mouseenter", toggleSubMenu2);
    //element.addEventListener("mouseleave", toggleSubMenu);
  });

  //To hide items when hovering over the other menu items
  const otherItems = document.querySelectorAll("#mobileNav #menu-main-menu > .menu-item:not(.menu-item-has-children)");

  otherItems.forEach((element) => {
    element.addEventListener("mouseenter", toggleSubMenu3);
  });

  function toggleSubMenu() {
    this.classList.toggle("active");
  }
  function toggleSubMenu2() {
    mobileSubMenuItems.forEach((element) => {
      element.classList.remove("active");
    });
    this.classList.add("active");
  }
  function toggleSubMenu3() {
    mobileSubMenuItems.forEach((element) => {
      element.classList.remove("active");
    });
  }

  const setHeaderHeight = () => {
    let headerOffset = header.getBoundingClientRect().bottom;
    let adminBarHeight;
    if (adminBar) {
      adminBarHeight = adminBar.offsetHeight;
      header.style.top = `${adminBarHeight}px`;
      document.querySelector('.menuHeader').style.paddingTop = `${adminBarHeight}px`;
    } else {
      adminBarHeight = 0;
    }
    /*if (headerOffset && adminBarHeight !== null) {
      mobileMenu.style.top = `${headerOffset - adminBarHeight}px`;
      mobileMenu.style.minHeight = `calc(100vh - ${headerOffset}px)`;
    }*/
  };

  // Initialize header and mobile menu height
  setHeaderHeight();

  // Listen for window resizing to update header and mobile menu height as screen size changes
  addEventListener("resize", (event) => {
    setHeaderHeight();
  });

  mobileMenuToggle.forEach((element) => {
    element.addEventListener("click", () => {
      element.classList.toggle("is-active");
      mobileMenu.classList.toggle("menu-open");
    });
  });
});



